<template>
    <div :class="{'gallery-grid': items[0].gridArea, 'gallery-flex': !items[0].gridArea }">
        <div v-for="(item, index) in items"
             :key="item.text"
             :class="{'gallery-item-grid': item.gridArea, 'gallery-item-flex': !item.gridArea, 'hovered': hoveredIndex === index }"
             :style="{ width: item.width + 'rem', height: item.height + 'rem', gridArea: item.gridArea}"
             class="relative place-self-auto" @mouseover="setHoveredIndex(index)" @mouseout="setHoveredIndex(null)">
            <a :href="item.routeLink">
                <img :alt="item.text" :src="item.imgLink"
                     :style="{ width: item.width + 'rem', height: item.height + 'rem' }" class="object-fit"/>
                <div class="overlay_bg" v-if="hoveredIndex === index">
                    <span>{{ item.text }}</span>
                </div>
                <div class="button-content button-image overlay" v-else>
                    <a :href="item.routeLink"><span>{{ item.text }}</span>&nbsp;<span
                    class="arrow"> &nbsp;></span></a></div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            hoveredIndex: null
        };
    },
    methods: {
        setHoveredIndex(index) {
            this.hoveredIndex = index;
        }
    }
}
</script>

<style scoped>
.gallery-grid {
    display: grid;
    grid-template-columns: none;
    grid-gap: 1rem;
    margin: 1rem;
}

.gallery-item-grid {
    position: relative;
    overflow: hidden;
}

.gallery-item-grid img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.gallery-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-between;
}

.gallery-item-flex {
    margin-bottom: 20px;
    text-align: center;
}

.gallery-item-flex img {
    display: block;
    object-fit: cover;
    margin: 0 auto;
}

.button-image {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    border-radius: 10px 0 0 0;
}

.overlay_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(16, 16, 16, 0.7); /* Donkere overlay */
    color: white; /* Tekstkleur op overlay */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    opacity: 0; /* Initieel onzichtbaar */
    transition: opacity 0.6s ease;
    pointer-events: none;
}

.hovered .overlay_bg {
    opacity: 1; /* Maak zichtbaar wanneer gehoverd */
}



@media screen and (max-width: 767px) {
    .gallery-item-flex, .gallery-item-grid {
        width: 100% !important;
    }

    .gallery-item-flex img, .gallery-item-grid img {
        width: 100% !important;
    }

    .gallery-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: space-between;

    }
}

</style>
